import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ApplicationSuccess() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <div className="flex flex-col items-center p-8 rounded-xl shadow-lg max-w-md w-full bg-white">
        <div className="text-green-500 mb-4 bg-green-500/20 rounded-full p-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-16 h-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center">
          We've received your application!
        </h2>
        <p className="text-gray-600 text-center">
          You will be notified by email when there is an update on your
          application.
        </p>
      </div>
    </div>
  );
}

function ApplicationForm() {
  const query = useQuery();
  const stream = query.get("stream");
  const id = query.get("id");
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false); // New state for confirmation popup

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const [applications, setApplications] = useState([
    { role: "", rationale: localStorage.getItem("rationale_0") || "" },
  ]);

  const [roles, setRoles] = useState([]); // State to store available roles

  useEffect(() => {
    let roleDropStatus = ""; // Define roleDropStatus based on stream
    if (stream === "sales") {
      roleDropStatus = "Live in Sales";
    } else if (stream === "generalist") {
      roleDropStatus = "Live in Gen";
    }

    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchActiveRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            roleDropStatus: stream, // Pass the roleDropStatus in the request
          },
        });

        // Assuming the response contains an array of roles
        const roleOptions = response.data.roles.map((role) => ({
          id: role.id,
          title: role["Primary Field"],
        }));

        // Sort roles alphabetically by title
        const sortedRoles = roleOptions.sort((a, b) =>
          a.title.localeCompare(b.title)
        );

        setRoles(sortedRoles);
      } catch (err) {
        console.error("Error fetching roles:", err);
      }
    };

    fetchRoles();
  }, [stream]); // Include stream in the dependency array to refetch when stream changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleApplicationChange = (index, e) => {
    const { name, value } = e.target;
    const newApplications = [...applications];
    newApplications[index][name] = value;
    setApplications(newApplications);

    // Save the rationale to localStorage
    if (name === "rationale") {
      localStorage.setItem(`rationale_${index}`, value);
    }
  };

  const handleAddApplication = () => {
    if (applications.length < 10) {
      setApplications([
        ...applications,
        {
          role: "",
          rationale:
            localStorage.getItem(`rationale_${applications.length}`) || "",
        },
      ]);
    }
  };

  const handleRemoveApplication = (index) => {
    const newApplications = [...applications];
    newApplications.splice(index, 1);
    setApplications(newApplications);
    localStorage.removeItem(`rationale_${index}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});

    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";

    applications.forEach((application, index) => {
      if (!application.role)
        errors[`role_${index}`] = `Role Choice ${index + 1} is required`;
      if (!application.rationale)
        errors[`rationale_${index}`] = `Rationale for Choice ${
          index + 1
        } is required`;
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (applications.length < 3) {
      setShowPopup(true); // Show popup if fewer than 3 applications
    } else {
      await submitApplications(); // Directly submit applications if there are 3 or more
    }
  };

  const submitApplications = async () => {
    setFormErrors({}); // Clear previous errors

    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      // Step 1: Fetch candidate based on the entered email
      const candidateResponse = await axios.get(
        "/api/candidates/fetchCandidateByEmail",
        {
          params: {
            email: formData.email, // Fetch the candidate using the entered email
          },
        }
      );

      console.log(candidateResponse);

      // Ensure we have the candidate data
      const candidate = candidateResponse.data.candidate.fields;
      console.log(candidateResponse.data.candidate);
      console.log(candidate);
      if (!candidate || !candidate["Candidate ID"]) {
        // If candidate is not found, set error on the email field
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "No candidate found with this email address",
        }));
        setLoading(false);
        return; // Exit the function if no candidate is found
      }

      console.log(candidate);

      const candidateId = candidate["Candidate ID"]; // Get the candidate ID

      const checkLimitResponse = await axios.get(
        "/api/interviews/checkApplicationLimit", // Endpoint of your serverless function
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            candidateId, // Pass the candidate ID to check the limit
          },
        }
      );

      const hasExceededLimit = checkLimitResponse.data.hasExceededLimit;

      if (hasExceededLimit) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email:
            "You have already submitted 6 applications within the last 3 days.",
        }));
        setLoading(false);
        return; // Exit the function if the application limit has been exceeded
      }

      // Step 2: Loop through each role application and create an interview for each
      for (let application of applications) {
        if (application.role && application.rationale) {
          // Step 3: Find the role ID based on the selected role title
          const selectedRole = roles.find(
            (role) => role.title === application.role
          );
          if (!selectedRole || !selectedRole.id) {
            throw new Error(`Role ${application.role} not found`);
          }

          const roleId = selectedRole.id;

          // Step 4: Create the interview using both candidateId and roleId
          const data = {
            fields: {
              Candidate: [candidateId], // Link the candidate ID
              Role: [roleId], // Link the role ID
              "Application Rationale": application.rationale, // Include rationale
              "Match type": "Application",
              Stage: "Application pending",
            },
          };

          // Step 5: Create the interview
          const interviewResponse = await axios.post(
            "/api/interviews/createInterview",
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log(
            `Interview created for role ${application.role}:`,
            interviewResponse.data
          );
        }
      }
      setLoading(false);
      setSubmissionSuccess(true); // Set submissionSuccess to true on successful submission

      // Clear the rationale from localStorage after submission
      applications.forEach((_, index) => {
        localStorage.removeItem(`rationale_${index}`);
      });
    } catch (err) {
      setLoading(false);
      console.error("Error:", err);

      if (err.response && err.response.data && err.response.data.error) {
        // If the backend returns a specific error about the candidate, show it
        const backendErrorMessage = err.response.data.error;
        if (backendErrorMessage.includes("Candidate not found")) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            email: "No candidate found with this email address",
          }));
        } else {
          // Handle other potential errors
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            email: "An error occurred. Please try again.",
          }));
        }
      }
      // Optionally, handle errors here, such as showing an error message to the user
    }
  };

  if (submissionSuccess) {
    // Render success component after submission
    return <ApplicationSuccess />;
  }

  if (id !== "l3qhEze7") {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-3xl font-bold">This page does not exist.</h1>
      </div>
    );
  }

  // If no roles are available, display the message
  if (roles.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <div className="text-center p-8 bg-white rounded-md shadow-md max-w-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            There are currently no roles available...
          </h2>
          <p className="text-gray-600">
            We run bi-weekly role drops, come back next Thursday!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center bg-white">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6 ">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>
      <div className="w-full p-20 flex max-w-[1000px] flex-col h-full bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-gray-900/10 pb-12">
              <h2 className="text-4xl pt-5 font-semibold leading-7 text-gray-900">
                {stream === "swe"
                  ? "Software Engineering Application Form"
                  : stream.charAt(0).toUpperCase() +
                    stream.slice(1) +
                    " Application Form"}
              </h2>
              <p className="mt-1 pt-4 text-md leading-6 text-gray-600">
                Please let us know which startup roles you'd like to apply to by{" "}
                <span className="font-bold text-black">
                  submitting this form by midnight on Sunday.{" "}
                </span>
                <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                  ✍️ You can apply to as many roles as you like, but please note
                  that most candidates
                  <span className="font-bold text-black">
                    {" "}
                    apply to at least 3 roles.
                  </span>
                </p>
                <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                  📝 For all applications,{" "}
                  <span className="font-bold text-black">
                    please write a short rationale{" "}
                  </span>
                  as to why you're interested in the role. Please use spellcheck
                  & keep this under 100 words!
                </p>
                <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      // Determine the correct URL based on the stream value
                      const url =
                        stream === "generalist"
                          ? "https://jumpstart-alumni.notion.site/Making-your-choices-6f9285950b3944c0bac672327ca8f8db?pvs=4"
                          : "https://jumpstart-alumni.notion.site/Making-your-choices-e07a5efa0b7f4b6b8414bfb5812b1420?pvs=4";
                      window.open(url, "_blank");
                    }}
                    className="underline text-blue-500 cursor-pointer"
                  >
                    Full guidance on making applications & writing rationales
                    can be found here.
                  </span>{" "}
                  For additional support, please reach out to{" "}
                  {stream === "generalist" ? (
                    <a
                      href="mailto:bethan@jumpstart-uk.com"
                      className="underline text-blue-500"
                    >
                      Bethan
                    </a>
                  ) : stream === "swe" ? (
                    <>
                      <a
                        href="mailto:kabir@jumpstart-uk.com"
                        className="underline text-blue-500"
                      >
                        Kabir
                      </a>
                    </>
                  ) : (
                    <a
                      href="mailto:milly@jumpstart-uk.com"
                      className="underline text-blue-500"
                    >
                      Milly
                    </a>
                  )}
                  . Otherwise, happy applying! 🤗
                </p>
              </p>

              {/* General Information */}
              <h2 className="block mt-10 text-3xl font-light leading-6 text-gray-900">
                General Information
              </h2>
              <div className="mt-6">
                <label
                  htmlFor="name"
                  className="block text-md font-semibold leading-6 text-gray-900"
                >
                  Full Name
                </label>
                <div className="mt-2 mb-4">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#02B491] sm:text-md sm:leading-6"
                    placeholder="John Smith"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {formErrors.name && (
                    <p className="text-red-600 text-sm">{formErrors.name}</p>
                  )}
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-md font-semibold leading-6 text-gray-900"
                >
                  Email Address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className={`block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ${
                      formErrors.email ? "ring-red-500" : "ring-gray-300"
                    } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#02B491] sm:text-md sm:leading-6`}
                    placeholder="john.smith@gmail.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <p className="text-red-600 text-sm">{formErrors.email}</p>
                  )}
                </div>
              </div>
              {/* Applications Section */}
              <h2 className="block mt-10 text-3xl font-light leading-6 text-gray-900">
                Applications
              </h2>
              {applications.map((application, index) => (
                <div key={index} className="mt-6">
                  <label
                    htmlFor={`role_choice_${index + 1}`}
                    className="block text-md font-semibold leading-6 text-gray-900"
                  >
                    Role Choice {index + 1}
                  </label>
                  <div className="mt-2 mb-4">
                    <select
                      name="role"
                      id={`role_choice_${index + 1}`}
                      className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#02B491] sm:text-md sm:leading-6"
                      value={application.role}
                      onChange={(e) => handleApplicationChange(index, e)}
                    >
                      <option value="" disabled>
                        Select a role
                      </option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.title}>
                          {role.title}
                        </option>
                      ))}
                    </select>
                    {formErrors[`role_${index}`] && (
                      <p className="text-red-600 text-sm">
                        {formErrors[`role_${index}`]}
                      </p>
                    )}
                  </div>

                  <label
                    htmlFor={`rationale_${index + 1}`}
                    className="block text-md font-semibold leading-6 text-gray-900"
                  >
                    Choice {index + 1} Rationale
                  </label>
                  <div className="mt-2 mb-4">
                    <textarea
                      name="rationale"
                      id={`rationale_${index + 1}`}
                      rows="4"
                      className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#02B491] sm:text-md sm:leading-6"
                      placeholder="Tell us why you're interested in the company + role (~100 words)"
                      value={application.rationale}
                      onChange={(e) => handleApplicationChange(index, e)}
                    ></textarea>
                    {formErrors[`rationale_${index}`] && (
                      <p className="text-red-600 text-sm">
                        {formErrors[`rationale_${index}`]}
                      </p>
                    )}
                  </div>

                  {index > 0 && (
                    <button
                      type="button"
                      className="text-red-600 text-sm flex hover:px-4 py-2 rounded-md hover:bg-red-300 hover:text-black"
                      onClick={() => handleRemoveApplication(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-5 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9L14.39 18m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                      Remove Choice {index + 1}
                    </button>
                  )}
                </div>
              ))}
              {applications.length < 10 && (
                <button
                  type="button"
                  onClick={handleAddApplication}
                  className="mt-4 flex text-[#02B491] hover:bg-green-300 hover:text-black py-2 hover:px-4 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  Add New Choice
                </button>
              )}
            </div>
          </div>

          <div className="flex w-full items-center -mt-5 justify-start gap-x-6">
            {loading ? (
              <div className="px-6 flex justify-center py-2 mb-4 w-full text-md bg-[#02B491] text-white hover:bg-[#00F2C2] hover:border-[#00F2C2] hover:text-black rounded-lg font-normal border-2 border-[#02B491]">
                {" "}
                <img src={LoadingBlack} className="h-6 " alt="Loading" />
              </div>
            ) : (
              <button
                type="submit"
                className="px-6 flex justify-center py-2 mb-4 w-full text-md bg-[#02B491] text-white hover:bg-[#00F2C2] hover:border-[#00F2C2] hover:text-black rounded-lg font-normal border-2 border-[#02B491]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 12 3.269 3.125A59.769 59.769 0 0121.485 12 59.768 59.768 0 013.27 20.875L5.999 12Zm0 0h7.5"
                  />
                </svg>
                Submit Applications
              </button>
            )}
          </div>
        </form>
      </div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-xl shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-left">
              Are you sure you only want to submit {applications.length}{" "}
              {applications.length === 1 ? "choice" : "choices"}?
            </h2>
            <p className="text-gray-600 mb-6 text-left">
              We usually advise candidates to apply to at least 3 roles to
              optimise their chances of going through the application stage.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  setShowPopup(false);
                  await submitApplications();
                }}
                className="px-4 py-2 bg-[#02B491] hover:bg-[#00F2C2] hover:text-black text-white rounded-md"
              >
                Submit Anyway
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicationForm;
