import React, { useEffect, useState, useRef } from "react";
import linkedin from "../../images/linkedin-logo.png";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import jumpstart from "../../images/jumpstart_logo.jpeg";
import { differenceInDays } from "date-fns";
import Office from "../../images/office-icon.png"; // Ensure you have the loading.svg in the correct path
import Languages from "../../images/languages-icon.png"; // Ensure you have the loading.svg in the correct path
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import axios from "axios";
import { useShortlisted } from "../../provider/ShortlistedContext";
import VideoIntro from "../../images/video-icon-green.png";
import TropheeIcon from "../../images/trophee-icon.png";
import { useAsyncError, useNavigate } from "react-router-dom";

const languageLogos = {
  JavaScript: "/images/coding_logo/javascript.png",
  Python: "/images/coding_logo/python.png",
  C: "/images/coding_logo/c.png",
  Swift: "/images/coding_logo/swift.png",
  "C#": "/images/coding_logo/csharp.png",
  "C++": "/images/coding_logo/cplusplus.png", // For C++
  Java: "/images/coding_logo/java.svg",
  Go: "/images/coding_logo/go.png",
  PHP: "/images/coding_logo/php.svg",
  Ruby: "/images/coding_logo/ruby.webp",
  Kotlin: "/images/coding_logo/kotlin.png",
  Rust: "/images/coding_logo/rust.png",

  // Newly added languages
  Clojure: "/images/coding_logo/clojure.png",
  Haskell: "/images/coding_logo/haskell.png",
  Dart: "/images/coding_logo/dart.png",
  Elixir: "/images/coding_logo/elixir.webp",
  "Objective-C": "/images/coding_logo/objc.svg",
  Perl: "/images/coding_logo/perl.png",
  Scala: "/images/coding_logo/scala.png",
  Typescript: "/images/coding_logo/typescript.png",
  React: "/images/coding_logo/react.png",
  Vue: "/images/coding_logo/vue.png",
  Angular: "/images/coding_logo/angular.png",
  "Next.js": "/images/coding_logo/next-js.svg",
};

function GeneralistCard({
  candidate,
  recommendedCandidates,
  roles,
  shortlistedCandidates,
  setRoles,
  setRolesNeedUpdate,
  role,
  setRoleNeedUpdate,
  setNewRoleForm,
  handleSelectCandidate,
  candidatesStatus = [],
  addToast,
}) {
  const navigate = useNavigate();
  const { shortlistedCount, setShortlistedCount } = useShortlisted();
  const [showRoleMenu, setShowRoleMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const roleMenuRef = useRef();
  const [companyLink, setCompanyLink] = useState("");

  const handleAddToShortlist = () => {
    setShowRoleMenu(!showRoleMenu);
  };

  const isNewCandidate = (timecreated) => {
    const createdDate = new Date(timecreated);
    const currentDate = new Date();
    return differenceInDays(currentDate, createdDate) < 7;
  };

  const formattedWork = candidate.work
    ? candidate.work
        .split(/(\d\)\s)/)
        .filter((part) => part.trim())
        .map((part, index) => {
          if (index % 2 === 1) {
            const [position, rest] = part.split("@");
            const [company, details] = rest ? rest.split(/-\s/, 2) : ["", ""];
            return (
              <div key={index} className="font-normal text-sm text-gray-500">
                <p className="text-black">
                  <span className="font-semibold">{position.trim()}</span> @{" "}
                  {company.trim()}
                </p>
                {details ? details.trim() : ""}
              </div>
            );
          }
          return (
            <p key={index} className="mb-2 font-normal text-sm text-gray-400 ">
              {part.replace(/\d\)\s/, "")}
            </p>
          );
        })
    : null;

  const handleRoleSelect = async (role, candidateId) => {
    setLoading(true); // Set loading state to true
    const currentShortlistedCandidates = role["Shortlisted Candidates"] || [];
    const updatedShortlistedCandidates = [
      ...new Set([...currentShortlistedCandidates, candidateId]),
    ];

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => {
        const updatedRoles = prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
        return updatedRoles;
      });

      const newUpdatedRoles = roles.map((r) =>
        r.id === updatedRoleData.id ? updatedRoleData : r
      );

      // Recalculate the shortlisted count
      const newShortlistedCount = newUpdatedRoles.reduce(
        (acc, role) =>
          acc +
          (role["Shortlisted Candidates"]
            ? role["Shortlisted Candidates"].length
            : 0),
        0
      );
      setShortlistedCount(newShortlistedCount);
      setRolesNeedUpdate(true);
      addToast(candidate.name);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Set loading state to false
      setShowRoleMenu(false);
    }
  };

  const handleRemoveCandidate = async (roles, role, candidateId) => {
    setLoading(true); // Set loading state to true
    const currentShortlistedCandidates = roles[role]["candidates"] || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates.filter(
      (candidate) => candidate !== candidateId
    );

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: roles[role].roleId,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => ({
        ...prevRoles,
        [role]: {
          ...prevRoles[role],
          candidates: updatedShortlistedCandidates,
        },
      }));

      const newShortlistedCount = shortlistedCount - 1;

      setShortlistedCount(newShortlistedCount);

      setRolesNeedUpdate(true);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Set loading state to false
      setShowRoleMenu(false);
    }
  };

  const isShortlistedForRole = (candidateId, roles) => {
    if (!roles || Object.keys(roles).length === 0) {
      return null; // Ignore if roles are not provided or empty
    }

    for (const role in roles) {
      if (
        roles[role].candidates &&
        roles[role].candidates.includes(candidateId)
      ) {
        return role;
      }
    }
    return null;
  };

  const roleShortlistedFor = shortlistedCandidates
    ? isShortlistedForRole(candidate.id, shortlistedCandidates)
    : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (roleMenuRef.current && !roleMenuRef.current.contains(event.target)) {
        setShowRoleMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";
  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "hover:bg-emerald-400",
          borderColor: "border-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "hover:bg-blue-400",
          borderColor: "border-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "hover:bg-orange-400",
          borderColor: "border-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "hover:bg-purple-400",
          borderColor: "border-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "hover:bg-gray-400",
          borderColor: "border-gray-400",
        };
    }
  };

  const candidateStatus = candidatesStatus.find(
    (candidateStatus) => candidateStatus.id === candidate.id
  );
  const statusStyles = {
    "Application pending": {
      color: "text-green-800",
      borderColor: "border-green-300",
      bgColor: "bg-green-50",
      message: (candidate, role) => `${candidate.name} applied to ${role}`,
    },
    Interviewing: {
      color: "text-blue-800",
      borderColor: "border-blue-300",
      bgColor: "bg-blue-50",
      message: (candidate, role) =>
        `${candidate.name} is being interviewed for ${role}`,
    },
    "Match requested": {
      color: "text-orange-800",
      borderColor: "border-orange-300",
      bgColor: "bg-orange-50",
      message: (candidate, role) =>
        `You have sent a request to ${candidate.name} for ${role}`,
    },
    "Rejected @ CV": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) =>
        `You rejected ${candidate.name} for ${role}`,
    },
    "Rejected @ Interview": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) =>
        `You rejected ${candidate.name} for ${role}`,
    },
    "Rejected Interview Request": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) =>
        `${candidate.name} declined invite to ${role}`,
    },
    "Company withdrawn": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) => `You withdrew from ${role}`,
    },
    "Candidate withdrawn": {
      color: "text-red-800",
      borderColor: "border-red-300",
      bgColor: "bg-red-50",
      message: (candidate, role) => `${candidate.name} withdrew from ${role}`,
    },
  };

  const statusStyle = candidateStatus
    ? statusStyles[candidateStatus.status]
    : null;

  const { bgColor, textColor, workBgColor, fillColor, bgColor2, borderColor } =
    getColorClasses(candidate.stream);

  const newRole = () => {
    handleSelectCandidate(candidate);
    setNewRoleForm(true);
  };

  useEffect(() => {
    const linkFromStorage = localStorage.getItem("companyLink");

    setCompanyLink(linkFromStorage);
  }, []);

  const displayShortlistButton =
    !candidateStatus ||
    [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ].includes(candidateStatus.status);

  const handleSvgClick = () => {
    setSvgMovedUp(true);
    setShowCopiedText(true);
    setTimeout(() => {
      setSvgMovedUp(false);
      setShowCopiedText(false);
    }, 2000);
  };

  const [showCopiedText, setShowCopiedText] = useState(false);
  const [svgMovedUp, setSvgMovedUp] = useState(false);

  const copyToClipboard = () => {
    const link = `${companyLink}&page=candidate/${candidate.url_id}`;
    navigator.clipboard.writeText(link).then(
      () => {
        handleSvgClick();
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const [showAll, setShowAll] = useState(false); // State to control "See more"

  const codingLanguagesArray = candidate.coding_languages
    ? candidate.coding_languages.split(",")
    : [];

  const displayedLanguages = showAll
    ? codingLanguagesArray
    : codingLanguagesArray.slice(0, 3);

  const validRightToWorkStatuses = [
    "No - I don't have the right to work in the UK",
    "No - Requires Visa sponsorship",
    "No - Looking for Visa sponsorship, but willing to self-sponsor if necessary",
    "No - Requires visa sponsorship - is well versed in the process + has a lawyer at hand if needed",
  ];

  return (
    <div className="relative max-w-lg mx-auto mt-5  bg-white rounded-xl  overflow-hidden flex flex-col h-full  hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition duration-400">
      <a
        href={`candidate/${candidate.url_id}`}
        onClick={(e) => {
          e.preventDefault();
          // Store the current page before opening the candidate details in a new tab
          sessionStorage.setItem("lastVisitedPage", window.location.pathname);

          // Open the candidate page in a new tab
          window.open(`candidate/${candidate.url_id}`, "_blank");
        }}
      >
        <div className="relative pt-4 px-4">
          <img
            className="w-full h-72 object-cover rounded-xl"
            src={candidate.image || fallbackImageUrl}
            alt="Candidate"
            onError={(e) => {
              e.target.src = fallbackImageUrl;
            }}
          />

          <span
            className={`absolute top-6 right-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            <svg
              className={`h-1.5 w-1.5 ${fillColor}`}
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {candidate.stream}
          </span>
          {isNewCandidate(candidate.createdTime) && (
            <span
              className="text-black absolute top-6 left-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium"
              style={{
                background: "white",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Recently added
            </span>
          )}
          {recommendedCandidates &&
            recommendedCandidates.includes(candidate.id) && (
              <span
                className="text-black absolute top-6 left-6 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium"
                style={{
                  background: "white",
                }}
              >
                <img
                  src={jumpstart}
                  title="Jumpstart icon"
                  className="h-6 w-6 rounded-sm"
                />
                Recommended for you
              </span>
            )}
        </div>
      </a>
      <div className="pt-2 px-4 flex flex-col flex-grow justify-between">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900">
              {candidate.name}
            </h5>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className={`size-6 ml-1 cursor-pointer transform ${
                svgMovedUp ? "hidden" : ""
              }`}
              onClick={copyToClipboard}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
              />
            </svg>

            {showCopiedText && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"
                />
              </svg>
            )}

            <img
              src={linkedin}
              alt={candidate.name}
              title="Linkedin icon"
              className="h-5 w-5 ml-1 rounded-sm"
              onClick={(e) => {
                e.preventDefault();
                let url = candidate.linkedin;
                if (!url.startsWith("https://")) {
                  url = "https://" + url;
                }
                window.open(url, "_blank");
              }}
              style={{ cursor: "pointer" }}
            />

            {candidate.cv && (
              <img
                src={cv}
                alt={candidate.cv}
                title="CV icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.cv, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.video && (
              <img
                src={VideoIntro}
                alt={candidate.video}
                title="Video Intro icon"
                className="h-6 w-6 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.video, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.github && (
              <img
                src={github}
                alt={candidate.github}
                title="Github icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.github, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.portfolio && (
              <svg
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.portfolio, "_blank");
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 cursor-pointer ml-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                />
              </svg>
            )}
          </div>
          <div className="flex flex-col text-right">
            <h5 className="text-sm tracking-tight text-gray-900 ">
              Expected Salary Range: {candidate.expectedSalaryRange}
            </h5>
            <h5 className="text-sm tracking-tight text-gray-900 ">
              {candidate.salary}
            </h5>
          </div>
        </div>

        <div className="flex">
          {candidate.roles &&
            typeof candidate.roles === "string" &&
            candidate.roles.split(",").map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {role.trim()}
              </span>
            ))}
          {candidate.sales && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.sales}
            </span>
          )}
          {candidate.swe_roles &&
            candidate.swe_roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {role.trim()}
              </span>
            ))}
          {candidate.exp_swe && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.exp_swe}
            </span>
          )}
          {candidate.time_coding && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.time_coding} of coding exp
            </span>
          )}

          {candidate.right_to_work &&
            validRightToWorkStatuses.includes(candidate.right_to_work) && (
              <span
                className={`inline-flex mb-2 mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {candidate.right_to_work}
              </span>
            )}
        </div>
        <div className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md text-black text-sm md:text-md font-medium flex gap-x-2">
          {codingLanguagesArray &&
            codingLanguagesArray.map((language, index) => (
              <img
                key={index}
                src={
                  languageLogos[language.trim()] || "path/to/default-logo.png"
                }
                alt={language}
                className="h-6 w-6"
              />
            ))}
        </div>
        <div className="hidden">
          {displayedLanguages.map((language, index) => (
            <span
              key={index}
              className={`inline-flex  mr-2 mb-2 border border-orange-600 items-center gap-x-1.5 rounded-full px-2 py-1 text-sm md:text-md font-medium ${textColor}`}
            >
              {language.trim()} {/* Trim to remove any extra spaces */}
            </span>
          ))}

          {codingLanguagesArray.length > 3 && (
            <button
              onClick={() => setShowAll(!showAll)}
              className="text-blue-500  underline"
            >
              {showAll ? "Show less" : "See more"}
            </button>
          )}
        </div>

        <div className="flex pb-2 pt-1">
          {candidate.working_style && (
            <p className=" pr-4 font-normal flex text-sm text-black ">
              <img className="h-6 pr-1 pb-1" src={Office} />
              {candidate.working_style}
            </p>
          )}
          {candidate.languages !== "N/A" && candidate.languages && (
            <p className="font-normal flex text-sm text-black ">
              <img className="h-5  pr-1" src={Languages} />
              {candidate.languages}
            </p>
          )}
        </div>

        <p className="text-sm">
          {candidate.education &&
            candidate.education.length > 0 &&
            (() => {
              const educationText = candidate.education;
              if (educationText.includes("; ")) {
                const splitText = educationText.split("; ")[1];
                const [beforeAt, afterAt] = splitText.split(" @ ");
                const [firstPart, ...restParts] = beforeAt.split("&");
                return (
                  <>
                    <span className="font-semibold">{firstPart.trim()}</span>
                    {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                    {afterAt ? ` @ ${afterAt}` : ""}
                  </>
                );
              }
              const [beforeAt, afterAt] = educationText.split(" @ ");
              const [firstPart, ...restParts] = beforeAt.split("&");
              return (
                <>
                  <span className="font-semibold">{firstPart.trim()}</span>
                  {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                  {afterAt ? ` @ ${afterAt}` : ""}
                </>
              );
            })()}
        </p>

        <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
          {candidate.recentTarget && (
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Recent Target Achieved:
              </span>{" "}
              {candidate.recentTarget}
            </div>
          )}

          {formattedWork}
        </div>

        {candidate.description && (
          <div className="mt-2 flex">
            <img src={TropheeIcon} className="h-5 w-5 "></img>

            <p className="font-normal pl-1 text-sm text-gray-700">
              {candidate.description}
            </p>
          </div>
        )}

        {candidate.most_impressive_swe && (
          <div className="mt-2 mb-4 flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
              />
            </svg>

            <p className="font-normal pl-1 text-sm text-gray-700">
              {candidate.most_impressive_swe}
            </p>
          </div>
        )}

        {statusStyle ? (
          <div
            className={`flex justify-between  mt-auto items-center py-2 px-2  ${
              displayShortlistButton ? "mt-2" : "my-4"
            } text-sm ${statusStyle.color} border ${
              statusStyle.borderColor
            } rounded-lg ${statusStyle.bgColor}`}
            role="alert"
          >
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>{statusStyle.message(candidate, candidateStatus.role)}</div>
            </div>
            {candidateStatus.status === "Application pending" && (
              <button
                onClick={() => {
                  navigate("/applications");
                }}
                className="ml-2 p-2 bg-[#02B491] hover:text-black hover:bg-green-50 border-[#02B491] border text-white rounded-md flex"
              >
                View application
              </button>
            )}
          </div>
        ) : null}

        {displayShortlistButton && (
          <>
            {roleShortlistedFor ? (
              <button
                onClick={() =>
                  handleRemoveCandidate(
                    shortlistedCandidates,
                    roleShortlistedFor,
                    candidate.id
                  )
                }
                className="px-6 py-2 mb-4 mt-auto text-sm border-2 border-red-500 text-black rounded-lg font-medium transform hover:bg-red-500 hover:text-white"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex justify-center items-center w-full rounded-lg">
                    <img
                      src={LoadingBlack}
                      className="h-6 fill-red-500"
                      alt="Loading"
                    />
                  </div>
                ) : (
                  `Remove from ${roleShortlistedFor} shortlist`
                )}
              </button>
            ) : (
              <>
                <button
                  onClick={handleAddToShortlist}
                  className={`px-6 py-2 mb-4 text-sm mt-auto text-black flex rounded-lg font-medium justify-center ${bgColor2} hover:text-white border-2 ${borderColor} bg-white`}
                >
                  {loading ? (
                    <div className="flex justify-center items-center w-full rounded-lg">
                      <img src={LoadingBlack} className="h-6 " alt="Loading" />
                    </div>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5 mr-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>
                      Add to shortlist
                    </>
                  )}
                </button>
                {showRoleMenu && (
                  <div
                    ref={roleMenuRef}
                    className="absolute mt-2 bottom-16 w-48 bg-white shadow-lg rounded-lg z-10"
                  >
                    {roles.map((role, index) => (
                      <button
                        key={index}
                        onClick={() => handleRoleSelect(role, candidate.id)}
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      >
                        {role["Role Title"]}
                      </button>
                    ))}
                    <button
                      onClick={() => newRole()}
                      className="block w-full px-4 py-2 text-left bg-[#02B491] rounded-b-lg text-sm text-white hover:bg-[#00f2c2] hover:text-black"
                    >
                      + New Role
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default GeneralistCard;
