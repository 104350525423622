import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/navbar2.js";
import EditCompanyProfile2 from "./new_pages/company_profile/edit_company_profile.js";
import Role from "./new_pages/roles/role.js";
import RedirectRole from "./new_pages/roles/redirect_role.js";

import Roles from "./pages/roles/roles.js";
import Roles2 from "./new_pages/roles/roles.js";
import Roles3 from "./new_pages/roles/roles2.js";
import CreateRole from "./new_pages/roles/create_role.js";
import Login from "./pages/login.js";
import NotFound from "./pages/not_found";
import Candidate from "./new_pages/candidates/candidate2.js";
import EditRole from "./new_pages/roles/edit_role.js";
import Browse from "./new_pages/candidates/browse/browse.js";
import InterviewRequest from "./pages/interviews/interview_request.js";
import Shortlists from "./new_pages/candidates/shortlist/shortlists.js";
import Shortlists2 from "./new_pages/candidates/shortlist/shortlists2.js";
import Shortlist from "./new_pages/candidates/shortlist/shortlist.js";
import Shortlist2 from "./new_pages/candidates/shortlist/shortlist2.js";

import RequestInterview from "./pages/shortlists/request_interview.js";
import RequestInterview2 from "./new_pages/candidates/shortlist/request_interview.js";
import NewRequestInterview from "./new_pages/candidates/shortlist/new_request_interview.js";

import { ShortlistedProvider } from "./provider/ShortlistedContext.js";
import FakeLogin from "./pages/fake_login.js";
import Applications from "./new_pages/applications/applications.js";
import Requests from "./new_pages/interviews/requests.js";
import InProcess from "./new_pages/interviews/in_process.js";
import Declined from "./new_pages/interviews/declined.js";
import Onboarding2 from "./new_pages/onboarding/new_onboarding.js";
import Onboarding from "./new_pages/onboarding/onboarding.js";
import RequestInterviewStreamlined from "./new_pages/candidates/shortlist/request_interview_streamlined.js";
import OpenAIRequestComponent from "./new_pages/onboarding/quick_create.js";
import Home from "./new_pages/home/home.js";
import ApplicationForm from "./candidates_pages/applications/application_form.js";
import RolesList from "./candidates_pages/roles/roles.js";
import NewRole from "./new_pages/roles/new_role.js";
import NewOnboarding from "./new_pages/onboarding/custom_onboarding.js";
import useGoogleAnalytics from "./useGoogleAnalytics"; // Import the custom hook
import CandidatesPreview from "./new_pages/candidates/candidates_preview.js";
import RoleCreated from "./new_pages/roles/congrats_screen.js";
import CongratsScreen from "./new_pages/onboarding/in_between_screen.js";
import JSPreview from "./new_pages/candidates/js_preview.js";
import TestSWE from "./new_pages/test_swe.js";

function App() {
  useGoogleAnalytics(); // Use the custom hook for tracking page views
  const location = useLocation();
  const noNavbarPaths = [
    "/role",
    "/onboarding",
    "/",
    "/signin",
    "/interview-request",
    "/application-form",
    "/roles-list",
    "/new-role2",
    "/new-onboarding",
    "/error",
    "/candidates-preview",
    "/role-created",
    "/account-created",
    "/jumpstart-preview",
    "*", // Ensure NotFound page does not show the navbar
  ]; // Add paths where you don't want the navbar

  return (
    <div className="App">
      <ShortlistedProvider>
        <div className="content bg-gray-100 h-full">
          {!noNavbarPaths.includes(location.pathname) && <Navbar />}

          <Routes>
            <Route path="/" element={<FakeLogin />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/browse-candidates" element={<Browse />} />
            <Route path="/shortlists2" element={<Shortlists />} />
            <Route path="/shortlist2" element={<Shortlist />} />
            <Route path="/shortlist" element={<Shortlist2 />} />

            <Route path="/shortlists" element={<Shortlists2 />} />
            <Route path="/request-interview2/" element={<RequestInterview />} />

            <Route path="/request-interview" element={<RequestInterview2 />} />
            <Route
              path="/request-interview-streamlined"
              element={<RequestInterviewStreamlined />}
            />
            <Route path="/role" element={<Role />} />
            <Route path="/role/:id" element={<RedirectRole />} />
            <Route path="/company-profile" element={<EditCompanyProfile2 />} />
            <Route path="/new-role" element={<CreateRole />} />
            <Route path="/new-role2" element={<NewRole />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/in-process" element={<InProcess />} />
            <Route path="/declined" element={<Declined />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/onboarding2" element={<Onboarding2 />} />
            <Route path="/new-onboarding" element={<NewOnboarding />} />

            <Route path="/quick-create" element={<OpenAIRequestComponent />} />
            <Route path="/candidates-preview" element={<CandidatesPreview />} />
            <Route path="/jumpstart-preview" element={<JSPreview />} />

            <Route path="*" element={<NotFound />} />
            <Route path="/error" element={<NotFound />} />
            <Route path="/interview-request" element={<InterviewRequest />} />
            <Route path="/roles" element={<Roles3 />} />

            <Route path="/candidate/:id" element={<Candidate />} />
            <Route path="/edit-role" element={<EditRole />} />

            <Route path="/candidate2/:id" element={<Candidate />} />
            <Route path="/roles2" element={<Roles />} />
            <Route path="/role" element={<Role />} />
            <Route path="/role-created" element={<RoleCreated />} />
            <Route path="/account-created" element={<CongratsScreen />} />

            <Route path="/application-form" element={<ApplicationForm />} />
            <Route path="/roles-list" element={<RolesList />} />

            <Route path="/test-swe" element={<TestSWE />} />
            <Route
              path="/new-request-interview"
              element={<NewRequestInterview />}
            />
          </Routes>
        </div>
      </ShortlistedProvider>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
