import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidates/condensed_card_application.js";
import Candidate from "../../components/candidates/candidate.js";
import SuccessSVG from "../../images/success-svg.svg";
import {
  RolesLoading,
  CandidateCardLoading,
} from "../../components/loading/loading_interviews.js";

export default function Applications() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingCandidates, setLoadingCandidates] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [filter, setFilter] = useState("");
  const [roles, setRoles] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeRole, setActiveRole] = useState("");
  const [interviewNeedUpdate, setInterviewNeedUpdate] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showFinalPopup, setShowFinalPopup] = useState(false);
  const [candidatesRequest, setCandidatesRequest] = useState(0);
  const [isRequested, setIsRequested] = useState(true);
  const [sortOrder, setSortOrder] = useState(0);

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;

        const transformedData = roles.map((role) => {
          return {
            id: role.id,
            title: role["Role Title"],
          };
        });

        setRoles(transformedData);
        setLoadingRoles(false);
      } catch (err) {
        setError(err.message);
        setLoadingRoles(false);
      }
    };

    const transformRoles = (roles) => {
      const roleSet = new Set(roles);
      if (roleSet.size === 1) {
        if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
        if (roleSet.has("Front End Developer")) return ["Front End"];
        if (roleSet.has("Back End Developer")) return ["Back End"];
      }
      if (
        roleSet.has("Front End Developer") &&
        roleSet.has("Back End Developer")
      ) {
        return ["Full Stack"];
      }
      if (roleSet.has("Full Stack Developer")) {
        return ["Full Stack"];
      }
      return roles;
    };

    const fetchInterviews = async () => {
      try {
        const token = localStorage.getItem("token");
        const stage = "Application pending"; // New stage parameter
        const response = await axios.get("/api/interviews/fetchInterviews", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { stage: encodeURIComponent(stage) }, // Include the stage parameter in the request
        });

        const interviews = response.data.interviews;

        console.log(response);

        const transformedCandidate = interviews.map((interview) => {
          console.log(interview);
          const mostRecentCohort =
            interview.fields["Most recent cohort (from Candidate)"];

          const stream =
            interview.fields["Stream (from CRM) (from Candidate)"][0];

          let sweRoles =
            interview.fields["SWE relevant roles (from Candidate)"];
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = interview.fields["Salary - cleaned (from Candidate)"];
          } else if (stream === "Sales") {
            salary =
              interview.fields["Base range + commission (from Candidate)"];
          } else if (stream === "Software Engineering") {
            salary = interview.fields["SWE Salary - cleaned (from Candidate)"];
          }

          return {
            role: interview.fields["Role"]?.[0] || undefined,
            ranking: interview.fields["Ranking"] || undefined,
            role_title: interview.fields["Role Title (from Role)"] || undefined,
            interviewId: interview.fields["id"] || undefined,
            url_id: interview.fields["ID (from Candidate)"] || undefined,
            stage: interview.fields["Stage"] || undefined,
            linkedin:
              interview.fields["LinkedIn raw URL (from Candidate)"]?.[0] ||
              undefined,
            cv: interview.fields["Raw CV (from Candidate)"]?.[0] || undefined,
            name: interview.fields["Name (from Candidate)"]?.[0] || undefined,
            lastName:
              interview.fields["Surname (from Candidate)"]?.[0] || undefined,
            video:
              interview.fields["Raw video intro (from Candidate)"]?.[0] ||
              undefined,
            education:
              interview.fields["Degree details (from Candidate)"]?.[0] ||
              undefined,
            roles:
              interview.fields[
                "Generalist roles suitable for (from Candidate)"
              ] || undefined,
            salary: salary || undefined,
            email: interview.fields["Email (from Candidate)"]?.[0] || undefined,
            working_style:
              interview.fields[
                "Preferred working style G/M/S (from Candidate)"
              ] || undefined,
            image:
              interview.fields["Photo (from Candidate)"]?.[0]?.url || undefined,
            work:
              interview.fields["Raw work experience (from Candidate)"]?.[0] ||
              undefined,
            languages:
              interview.fields["Languages & technologies (from Candidate)"] ||
              undefined,
            description:
              interview.fields["Top achievement (from Candidate)"] || undefined,
            mostRecentCohort:
              interview.fields["Most recent cohort (from Candidate)"]?.[0] ||
              undefined,
            recentTarget:
              interview.fields["Recent target achieved (from Candidate)"] ||
              undefined,
            stream: stream || undefined, // Add the stream field
            rationale: interview.fields["Application Rationale"] || undefined,
            sales:
              interview.fields[
                "Proven Sales Experience / High Potential for Sales (from Candidate)"
              ] || undefined,
            github: interview.fields["Raw Github (from Candidate)"][0] || "",
            exp_swe:
              interview.fields["Level of exp SWE (from Candidate)"] || "",
            portfolio:
              interview.fields["Raw Portfolio (from Candidate)"][0] || "",
            time_coding:
              interview.fields["Time spent coding (from Candidate)"] || "",
            coding_languages:
              interview.fields["Coding languages (from Candidate)"]?.[0] || "",
            swe_roles: sweRoles,
          };
        });

        setCandidates(transformedCandidate);

        console.log(transformedCandidate);

        const requestCount = transformedCandidate.filter(
          (candidate) => candidate.stage === "Application pending"
        ).length;
        setCandidatesRequest(requestCount);

        setLoadingCandidates(false);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "Internal Server Error" &&
          err.response.data.error ===
            "Error: No interviews found for this company"
        ) {
          setCandidates([]); // Set empty state
        } else {
          setError(err.message);
        }
        setLoadingCandidates(false);
      }
    };

    if (!interviewNeedUpdate) return;

    fetchRoles();
    fetchInterviews();
    setInterviewNeedUpdate(false);
  }, [interviewNeedUpdate]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const sortCandidates = (candidates) => {
    if (sortOrder === 1) {
      return [...candidates].sort((a, b) => {
        if (a.ranking === "Thumbs up") return -1;
        if (b.ranking === "Thumbs up") return 1;
        if (a.ranking === "Not ranked") return -1;
        if (b.ranking === "Not ranked") return 1;
        return 0;
      });
    } else if (sortOrder === 2) {
      return [...candidates].sort((a, b) => {
        if (a.ranking === "Thumbs down") return -1;
        if (b.ranking === "Thumbs down") return 1;
        if (a.ranking === "Not ranked") return -1;
        if (b.ranking === "Not ranked") return 1;
        return 0;
      });
    } else if (sortOrder === 3) {
      // New sorting logic for "Not ranked" at the top
      return [...candidates].sort((a, b) => {
        if (a.ranking === "Not ranked") return -1;
        if (b.ranking === "Not ranked") return 1;
        return 0;
      });
    }
    return candidates;
  };

  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder + 1) % 4); // Cycle through 0, 1, 2, 3
  };

  const filteredData = candidates.filter((candidate) => {
    console.log(activeRole);
    if (filter && candidate.stage !== filter) return false;
    if (activeRole && candidate.role_title[0] !== activeRole) return false;
    return true;
  });

  const sortedData = sortCandidates(filteredData);

  return (
    <>
      <div className="relative bg-gray-100 min-h-screen">
        <div>
          <div className="flex lg:ml-[200px] h-full pb-20 justify-center items-center bg-gray-100">
            <div className="max-w-[1500px] w-full">
              <div className="bg-white pl-12 px-4 pt-6 pb-4 fixed top-18 z-20 w-full border-b">
                <div className="flex">
                  <div className="flex justify-left items-center">
                    <div>
                      <h2 className="text-3xl">Applications</h2>
                      <div className="flex">
                        <div className="mt-2 mr-8">
                          There are{" "}
                          <span className="font-bold">
                            {sortedData.length}{" "}
                          </span>{" "}
                          candidates that are waiting for a response from you.
                        </div>

                        <button
                          onClick={handleSortClick}
                          className={`flex p-2 rounded-md ${
                            sortOrder === 1 ||
                            sortOrder === 2 ||
                            sortOrder === 3
                              ? "bg-green-100"
                              : "hover:bg-gray-100"
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-6 pr-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                            />
                          </svg>
                          {sortOrder === 1 ? (
                            <>Sorted with Thumbs Up at the top</>
                          ) : sortOrder === 2 ? (
                            <>Sorted with Thumbs Down at the top</>
                          ) : sortOrder === 3 ? (
                            <>Sorted with Undecided at the top</>
                          ) : (
                            "Sort"
                          )}
                        </button>

                        <div className="ml-4">
                          <select
                            id="role-filter"
                            value={activeRole}
                            onChange={(e) => setActiveRole(e.target.value)}
                            className="border p-2 rounded-md"
                          >
                            <option value="">Filter by role</option>{" "}
                            {/* Default option to show all candidates */}
                            {roles.map((role) => (
                              <option key={role.id} value={role.title}>
                                {role.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loadingCandidates ? (
                <div className="grid pt-28 ml-20 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <CandidateCardLoading key={index} />
                    ))}
                </div>
              ) : candidates.length === 0 ? (
                <div className="flex justify-center">
                  <div className="mt-60 bg-white max-w-md  rounded-md p-4 text-center">
                    <h2 className="text-xl">
                      You don't have any applications yet!
                    </h2>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/browse-candidates");
                      }}
                      className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      Browse Candidates
                    </button>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/in-process");
                      }}
                      className="ml-2 px-4 mt-4 text-md py-2 hover:bg-[#02B491] hover:text-white rounded-md bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      View in process
                    </button>
                  </div>
                </div>
              ) : (
                <div className="grid pt-28 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {sortedData.map((candidate, index) => (
                    <CandidateCard
                      key={index}
                      candidate={candidate}
                      onSelectCandidate={handleSelectCandidate}
                      setInterviewNeedUpdate={setInterviewNeedUpdate}
                      setShowPopup={setShowPopup}
                      setShowFinalPopup={setShowFinalPopup}
                      candidatesRequest={candidatesRequest}
                      setCandidatesRequest={setCandidatesRequest}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {showFinalPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 py-10 rounded-md shadow-md text-center">
              <h2 className="text-2xl font-bold mb-2">
                Thank you for responding to all your applications!
              </h2>
              <p className="text-md">
                Do you want to see more potential great fits for your company?
              </p>
              <div className="mt-4 space-x-2">
                <button
                  onClick={() => {
                    setShowFinalPopup(false);
                    navigate("/browse-candidates");
                  }}
                  className="px-4 py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                >
                  Browse candidates
                </button>
                <button
                  onClick={() => {
                    setShowFinalPopup(false);
                    navigate("/in-process");
                  }}
                  className="px-4 py-2 rounded-md hover:bg-[#02B491] hover:text-white border-2 border-[#02B491]"
                >
                  View my candidates
                </button>
              </div>
            </div>
          </div>
        )}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-[600px] py-10 p-6 rounded-md shadow-md text-center">
              <h2 className="text-3xl font-bold mb-2">Congrats!</h2>
              <p className="text-md">You will now be introduced over email</p>
              <div className="mt-4">
                <button
                  onClick={() => setShowPopup(false)}
                  className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                >
                  Great!
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
