import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidates/condensed_card.js";
import {
  RolesLoading,
  CandidateCardLoading,
} from "../../components/loading/loading_interviews.js";

export default function Declined() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingCandidates, setLoadingCandidates] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [filter, setFilter] = useState("");
  const [roles, setRoles] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeRole, setActiveRole] = useState("");
  const [interviewNeedUpdate, setInterviewNeedUpdate] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showFinalPopup, setShowFinalPopup] = useState(false);
  const [candidatesRequest, setCandidatesRequest] = useState(0);
  const [isRequested, setIsRequested] = useState(true);

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;

        const transformedData = roles.map((role) => {
          return {
            id: role.id,
            title: role["Role Title"],
          };
        });

        setRoles(transformedData);
        setLoadingRoles(false);
      } catch (err) {
        setError(err.message);
        setLoadingRoles(false);
      }
    };

    const transformRoles = (roles) => {
      const roleSet = new Set(roles);
      if (roleSet.size === 1) {
        if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
        if (roleSet.has("Front End Developer")) return ["Front End"];
        if (roleSet.has("Back End Developer")) return ["Back End"];
      }
      if (
        roleSet.has("Front End Developer") &&
        roleSet.has("Back End Developer")
      ) {
        return ["Full Stack"];
      }
      if (roleSet.has("Full Stack Developer")) {
        return ["Full Stack"];
      }
      return roles;
    };

    const fetchInterviews = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/interviews/fetchInterviews", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const interviews = response.data.interviews;

        const transformedCandidate = interviews.map((interview) => {
          const mostRecentCohort =
            interview.fields["Most recent cohort (from Candidate)"];
          let stream = "";

          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }

          let sweRoles = interview.fields["SWE relevant roles"];
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = interview.fields["Salary - cleaned (from Candidate)"];
          } else if (stream === "Sales") {
            salary =
              interview.fields["Base range + commission (from Candidate)"];
          } else if (stream === "Software Engineering") {
            salary = interview.fields["SWE Salary - cleaned (from Candidate)"];
          }

          return {
            role: interview.fields["Role"]?.[0] || undefined,
            role_title: interview.fields["Role Title (from Role)"] || undefined,
            interviewId: interview.fields["id"] || undefined,
            url_id: interview.fields["ID (from Candidate)"] || undefined,
            stage: interview.fields["Stage"] || undefined,
            linkedin:
              interview.fields["LinkedIn raw URL (from Candidate)"]?.[0] ||
              undefined,
            cv: interview.fields["Raw CV (from Candidate)"]?.[0] || undefined,
            name: interview.fields["Name (from Candidate)"]?.[0] || undefined,
            lastName:
              interview.fields["Surname (from Candidate)"]?.[0] || undefined,
            video:
              interview.fields["Raw video intro (from Candidate)"] || undefined,
            education:
              interview.fields["Degree details (from Candidate)"]?.[0] ||
              undefined,
            roles:
              interview.fields[
                "Generalist roles suitable for (from Candidate)"
              ] || undefined,
            salary: salary || undefined,
            email: interview.fields["Email (from Candidate)"]?.[0] || undefined,
            working_style:
              interview.fields[
                "Preferred working style G/M/S (from Candidate)"
              ] || undefined,
            image:
              interview.fields["Photo (from Candidate)"]?.[0]?.url || undefined,
            work:
              interview.fields["Raw work experience (from Candidate)"]?.[0] ||
              undefined,
            languages:
              interview.fields["Languages & technologies (from Candidate)"] ||
              undefined,
            description:
              interview.fields["Top achievement (from Candidate)"] || undefined,
            mostRecentCohort:
              interview.fields["Most recent cohort (from Candidate)"]?.[0] ||
              undefined,
            recentTarget:
              interview.fields["Recent target achieved (from Candidate)"] ||
              undefined,
            stream: stream || undefined, // Add the stream field
            rationale: interview.fields["Application Rationale"] || undefined,
            sales:
              interview.fields[
                "Proven Sales Experience / High Potential for Sales (from Candidate)"
              ] || undefined,
          };
        });

        // Filter out candidates with the specified stages
        const filteredCandidates = transformedCandidate.filter(
          (candidate) =>
            candidate.stage !== "Interviewing" &&
            candidate.stage !== "Application pending" &&
            candidate.stage !== "Match requested"
        );

        setCandidates(filteredCandidates);

        const requestCount = filteredCandidates.filter(
          (candidate) => candidate.stage === "Application pending"
        ).length;
        setCandidatesRequest(requestCount);

        setLoadingCandidates(false);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "Internal Server Error" &&
          err.response.data.error ===
            "Error: No interviews found for this company"
        ) {
          console.log("Yes");
          setCandidates([]); // Set empty state
          setLoadingCandidates(false);
        } else {
          setError(err.message);
          setLoadingCandidates(false);
        }
      }
    };

    if (!interviewNeedUpdate) return;

    fetchRoles();
    fetchInterviews();
    setInterviewNeedUpdate(false);
  }, [interviewNeedUpdate]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredData = candidates.filter((candidate) => {
    if (filter && candidate.stage !== filter) return false;
    if (activeRole && candidate.role !== activeRole) return false;
    return true;
  });

  return (
    <>
      <div className="relative bg-gray-100 min-h-screen">
        <div>
          <div className="flex lg:ml-[200px] h-full pb-20 justify-center items-center bg-gray-100">
            <div className="max-w-[1500px] w-full">
              <div className="bg-white pl-12 pt-6 pb-4 fixed top-18 z-20 w-full border-b">
                <div className="flex justify-left items-center">
                  <div>
                    <h2 className="text-3xl">Declined</h2>
                    <div className="mt-2">
                      You have{" "}
                      <span className="font-bold">{filteredData.length} </span>{" "}
                      declined candidates.
                    </div>
                  </div>
                </div>
              </div>
              {loadingCandidates ? (
                <div className="grid pt-28 ml-12 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {Array(6)
                    .fill(0)
                    .map((_, index) => (
                      <CandidateCardLoading key={index} />
                    ))}
                </div>
              ) : candidates.length === 0 ? (
                <div className="flex justify-center">
                  <div className="mt-60 bg-white max-w-md rounded-md p-4 text-center">
                    <h2 className="text-xl">
                      You don't have any declined candidates yet!
                    </h2>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/browse-candidates");
                      }}
                      className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      Browse Candidates
                    </button>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/in-process");
                      }}
                      className="ml-2 px-4 mt-4 text-md py-2 hover:bg-[#02B491] hover:text-white rounded-md bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      View in process
                    </button>
                  </div>
                </div>
              ) : (
                <div className="grid pt-28 ml-12 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredData.map((candidate, index) => (
                    <CandidateCard
                      key={index}
                      candidate={candidate}
                      onSelectCandidate={handleSelectCandidate}
                      setInterviewNeedUpdate={setInterviewNeedUpdate}
                      setShowPopup={setShowPopup}
                      setShowFinalPopup={setShowFinalPopup}
                      candidatesRequest={candidatesRequest}
                      setCandidatesRequest={setCandidatesRequest}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
